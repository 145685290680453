import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const PhoneButton = props => {
  const data = useStaticQuery(graphql`
    {
      strapiConfigurationGenerale {
        numerodetelephone
      }
    }
  `)

  return (
    <a
      href={`tel:${data.strapiConfigurationGenerale.numerodetelephone}`}
      className="relative z-10 flex items-center rounded-l-full rounded-r-full bg-white py-2 px-6 font-bold text-gray-900"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
      {' '}
      <span>Contactez-nous</span>
    </a>
  )
}

PhoneButton.propTypes = {}

export default PhoneButton
