import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { SEO } from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import FormationFaq from "../components/Formations/FormationFAQ"
import PhoneButton from "../components/Formations/PhoneButton"

function SVGBottomIllustration() {
  return (
    <svg
      id="ffc7bca1-1b15-42b2-bdbb-c3aa5c95f9e7"
      className="-mr-4 w-full"
      data-name="visual"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 901.05 115.61"
    >
      <path
        d="M-.25,87.34l50.11,4.6c50.1,4.61,150.31,13.82,250.4,17.41s200.07,1.57,299.82-11.7,199.28-37.76,249-50l49.76-12.25L900.8,130l-50,1L600.86,136l-299.94,6.08L51,147.19l-50,1Z"
        transform="translate(0.25 -35.39)"
        fill="#ac1e44"
      />
      <path
        d="M0,86l50,6c50,6,150,18,250,24s200,6,300-6S800,74,850,62l50-12V151H0Z"
        transform="translate(0.25 -35.39)"
        fill="#fff"
      />
    </svg>
  )
}

const FormationPage = ({ location, data, ...props }) => {
  const formation = data.strapiFormation
  console.log(formation)
  return (
    <Layout>
      <main className="md:pb-24">
        <div className="relative mx-auto max-w-[2100px] overflow-hidden">
          <div className="wrapper-gatsby-image bg-gradient-publication absolute inset-0 object-cover">
            <GatsbyImage
              backgroundColor="#ac1e44"
              className="h-full w-full"
              layout={"fullWidth"}
              aspectRatio={16 / 9}
              image={
                formation.illustration.localFile.childImageSharp.gatsbyImageData
              }
              alt={`Illustration ${formation.nom}`}
            />
          </div>
          <div className="container mx-auto">
            <div className="flex h-72 items-center justify-center pl-5 lg:h-[32rem] lg:pr-96">
              <h1 className="text-shadow relative z-10 text-3xl font-bold text-white lg:text-7xl">
                {formation.nom_long || formation.nom}
              </h1>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 top-auto">
            <SVGBottomIllustration />
          </div>
        </div>
        <section className="container mx-auto">
          <div className="prose mt-6 px-4 font-bold text-ehsl-blue-400 lg:prose-lg lg:px-0">
            {formation.description}
          </div>
        </section>
        {formation.childStrapiFormationContenuTextnode && (
          <section className="container mx-auto mt-12">
            <div className="grid gap-6 lg:grid-cols-5">
              {formation.childStrapiFormationContenuTextnode.childMarkdownRemark
                .tableOfContents && (
                <div className="hidden lg:block">
                  <div className="ToC sticky top-10 overflow-hidden rounded-t-2xl pb-6 shadow">
                    <div className="border-b-4 border-b-ehsl-purple-500 bg-gray-800 py-4 px-4 font-bold text-white">
                      Sur cette page :
                    </div>
                    <div
                      className="px-4 pt-4 text-sm"
                      dangerouslySetInnerHTML={{
                        __html:
                          formation.childStrapiFormationContenuTextnode
                            .childMarkdownRemark.tableOfContents,
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="px-4 lg:col-span-3 lg:px-0">
                <div
                  className="text-enrichi prose md:prose-lg"
                  dangerouslySetInnerHTML={{
                    __html:
                      formation.childStrapiFormationContenuTextnode
                        .childMarkdownRemark.html,
                  }}
                />
                {formation.foireAuxQuestions && (
                  <FormationFaq faq={formation.foireAuxQuestions} />
                )}
              </div>
            </div>
          </section>
        )}
        <section id="brochure" className="my-12">
          <div className="container mx-auto">
            <div className="relative overflow-hidden lg:rounded-l-full lg:rounded-r-full">
              <div className="bg-gradient-publication flex flex-col items-center justify-between gap-5 bg-ehsl-purple-400 px-6 py-6 text-xl font-bold text-white lg:flex-row lg:py-2">
                <span className="relative z-[1]">
                  Besoin de plus de renseignements ?
                </span>
                <div className="flex items-center space-x-5">
                  {formation.brochure &&
                    formation.brochure.map(brochure => {
                      return (
                        <Link
                          to={brochure.localFile.publicURL}
                          title={`${brochure.localFile.name}${brochure.localFile.ext}`}
                          className=" relative z-10 flex items-center rounded-l-full rounded-r-full bg-white py-2 px-6 font-bold text-gray-900"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>{" "}
                          <span>{brochure.name}</span>
                        </Link>
                      )
                    })}
                  <PhoneButton />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

FormationPage.propTypes = {
  location: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
}

export default FormationPage

export const Head = ({ data }) => (
  <SEO
    title={data.strapiFormation.nom}
    description={data.strapiFormation.description}
  />
)

export const query = graphql`
  query ($slug: String!) {
    strapiFormation(slug: { eq: $slug }) {
      locale
      nom
      nom_long
      description
      brochure {
        name
        localFile {
          publicURL
          name
          ext
        }
      }
      illustration {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      childStrapiFormationContenuTextnode {
        childMarkdownRemark {
          html
          tableOfContents
          excerpt
        }
      }
      foireAuxQuestions {
        question {
          question
          reponse {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
